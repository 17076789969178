<script setup lang="ts">
const emit = defineEmits(['remove'])
</script>

<template>
  <UiPopup class="conversation-actions-popup">
    <template #trigger-title>
      <span class="icon-more" />
    </template>
    <div class="conversation-actions-panel">
      <button class="danger" @click="emit('remove')">
        Delete Conversation
      </button>
      <span>
        This conversation will be deleted from all the participants!
      </span>
    </div>
  </UiPopup>
</template>

<style lang='scss'>
.conversation-actions-panel {
  position: relative;
  padding: var(--padding-base);
  width: min-content;
}
</style>
