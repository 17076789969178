<script setup lang="ts">
const props = defineProps<{
  id: string
}>()

const { data: status, pending } = await useAsyncData(`status:${props.id}`, () => fetchStatus(`${props.id}`))
</script>

<template>
  <div>
    <div v-if="pending" class="spinner-wrap">
      <SpinnerIcon />
    </div>
    <StatusPopover v-else-if="status" :status="status" />
  </div>
</template>
