<script setup lang="ts">
import type { MenuItem } from '~/types'

type Locale = {
  code: string
  name: string
}
const { locales, locale } = useLocale()

const localesOptions = computed<MenuItem<Locale>[]>(() => locales.map(({ name, code }) => ({
  label: name,
  checked: locale.value === code,
  command: () => { locale.value = code },
  meta: { code, name }
})))
</script>

<template>
  <UiDropdownSelect
    key="code"
    :options="localesOptions"
    placement="top-start">
    <template #title>
      {{ locale }}
    </template>
  </UiDropdownSelect>
</template>
