<script setup lang="ts">
const props = withDefaults(defineProps<{
  count?: number | boolean
}>(), {
  count: true,
})
const withCount = computed(() => typeof props.count === 'number')
</script>

<template>
  <span v-if="count" class="ui-unread-badge" :class="{ 'with-count': withCount }">
    <span v-if="withCount">{{ count }}</span>
  </span>
</template>

<style lang='scss'>
.ui-unread-badge {
  --s: var(--padding-small);
  --p: calc(var(--padding-mini));
  position: absolute;
  top: var(--p);
  right: var(--p);
  min-height: var(--s);
  min-width: var(--s);
  border-radius: 999px;
  font-size: var(--font-size-micro);
  background-color: rgb(255, 0, 93);
  color: white;

  &.with-count {
    --ss: calc(var(--s) + var(--padding-small));
    --p: calc(var(--padding-mini) * -0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    padding: 0 3px;
    min-width: var(--ss);
    height: var(--ss);
  }
}
</style>
