<script setup lang="ts">
type PlacementString = 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

withDefaults(defineProps<{
  placement: PlacementString
  cssClass?: string
}>(), {
  placement: 'top-left',
  cssClass: ''
})
defineExpose({ show: () => { open.value = true } })

const open = ref(false)
</script>

<template>
  <span class="popover-float-trigger">
    <slot name="trigger">
      <button class="compact" @click="open = !open">
        <slot name="trigger-title">
          Popover
        </slot>
      </button>
    </slot>
  </span>
  <Teleport v-if="open" to="body" v-bind="$attrs">
    <div class="popover-float-wrap theme theme-panel" :class="[placement, cssClass]">
      <header class="popover-header">
        <div class="left" />
        <div class="right">
          <button class="compact" @click="open = false">
            <span class="icon-close" />
          </button>
        </div>
      </header>
      <div class="popover-body">
        <slot />
      </div>
    </div>
  </Teleport>
</template>

<style lang='scss'>
:root {
  --pop-float-top: var(--padding-small);
  --pop-float-right: var(--padding-small);
  --pop-float-bottom: var(--padding-small);
  --pop-float-left: var(--padding-small);
}

.popover-float-wrap {
  position: fixed;
  z-index: var(--z-index-float);
  max-height: min(var(--base-size) * 15, 100svh - var(--pop-float-bottom) - var(--pop-float-top));
  width: min(var(--base-size) * 10, 100vw - var(--base-size));
  overflow: clip;
  border-radius: var(--corner-radius);
  display: grid;
  grid-template-rows: 1fr;
  box-shadow: var(--grid-active-shadow);

  &[class*='top'] {
    top: var(--pop-float-top);
  }

  &[class*='bottom'] {
    bottom: var(--pop-float-bottom);
  }

  &[class*='left'] {
    left: var(--pop-float-left);
  }

  &[class*='right'] {
    right: var(--pop-float-right);
  }

  .popover-header {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }

  .popover-body {
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
  }
}
</style>
