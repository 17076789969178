<script setup lang="ts">
import type { mastodon, ConversationMessage } from '#types'
import type { ConversationMessageGroup } from '~/types'

const props = defineProps<{
  conversation: mastodon.v1.Conversation
  account: mastodon.v1.Account
}>()
const emit = defineEmits<{ (evt: 'remove'): void }>()

const loading = ref(false)
const messageToEdit = ref<ConversationMessage>()
const records = useCache<ConversationMessage[]>(computed(() => `conversation-${props.conversation.id}`))

const chatBody = ref<HTMLElement>()
const accounts = computed<mastodon.v1.Account[]>(() => props.conversation.accounts?.filter(a => !isSelfAccount(a)) ?? [])

watch([props, records], async ([{ conversation }]) => {
  if (conversation?.id && records.value === undefined) {
    try {
      loading.value = true
      const headers = useRequestHeaders(['cookie'])
      records.value = await $fetch<ConversationMessage[]>(`/api/conversations/${conversation.id}/messages`, { headers })
    }
    finally {
      loading.value = false
    }
  }
}, { immediate: true })

const messageGroups = computed<ConversationMessageGroup[]>(() => {
  return (records.value ?? [])
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    .reduce<ConversationMessageGroup[]>((groups, message) => {
      const timestamp = new Date(message.createdAt).getTime()
      const preGroup = groups[groups.length - 1]
      let startNewGroup = true
      if (preGroup?.account.id === message.account.id) {
        const preMessages = preGroup.messages
        const preTimestamp = preMessages[preMessages.length - 1].createdAt
        startNewGroup = timestamp - preTimestamp > 1000 * 60 * 60 // 60 min
      }
      if (startNewGroup) {
        groups.push({
          own: Boolean(compareHandle(props.account.id, message.account.id)),
          account: message.account,
          messages: [message],
        })
      }
      else {
        preGroup.messages.push(message)
      }
      return groups
    }, [])
})

onMounted(scrollToLastOne)
watch(records, scrollToLastOne)

async function scrollToLastOne() {
  if (chatBody.value) {
    await nextTick()
    chatBody.value.scrollTop = chatBody.value.scrollHeight
  }
}

function sendMessage({ publishId, html }: { publishId: string, html: string }) {
  records.value?.unshift({
    id: '',
    publishId,
    conversationId: props.conversation.id,
    account: props.account,
    content: html,
    createdAt: new Date().getTime(),
    state: 'pending',
  })
}

function removeMessage(message: ConversationMessage) {
  const index = records.value?.findIndex(({ id, publishId }) => (id && id === message.id) || (publishId && publishId === message.publishId)) ?? -1
  if (index >= 0) {
    records.value?.splice(index, 1)
  }
  if (message.id) {
    const headers = useRequestHeaders(['cookie'])
    $fetch(`/api/conversations/${props.conversation.id}/messages/${message.id}`, { method: 'DELETE', headers }).catch(noop)
  }
}

function editMessage(message: ConversationMessage) {
  messageToEdit.value = message
}

// function setMessageState(message: ConversationStatus, state: ConversationStatus['state']) {
//   const index = records.value?.findIndex(status => status.publishId === message.publishId) ?? -1
//   if (index > -1) {
//     records.value?.splice(index, 1)
//   }
//   message.state = state
//   records.value?.unshift(message)
// }
</script>

<template>
  <div class="conversation-panel">
    <div ref="chatBody" class="conversation-body">
      <header class="conversation-header">
        <div class="names">
          <span>{{ $t('conversation-with') }}</span>
          <span v-for="acc in accounts" :key="acc.id">
            <NuxtLink :to="getAccountRoute(acc)">
              <span>{{ getDisplayName(acc) }}</span>
            </NuxtLink>
          </span>
        </div>
        <ConversationActionsPopup @remove="emit('remove')" />
      </header>
      <SpinnerIcon v-if="loading" />
      <ul v-else class="conversation-messages">
        <ConversationMessageGroup
          v-for="group in messageGroups"
          :key="`gr${group.messages[0].createdAt}`"
          :group="group"
          :conversation="conversation"
          @edit="editMessage"
          @remove="removeMessage"
        />
      </ul>
    </div>
    <ConversationMessageEditor :conversation="conversation" :message="messageToEdit" @send="sendMessage" />
  </div>
</template>

<style lang="scss">
.conversation-panel {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  overflow: hidden;

  .conversation-body {
    padding-inline-end: var(--padding-big);
    overflow: auto;
    overscroll-behavior: contain;
  }

  .conversation-header {
    position: sticky;
    top: var(--padding-small);
    z-index: 10;
    font-size: var(--font-size-caption);
    display: flex;
    gap: var(--padding-small);
    height: calc(var(--button-height) - var(--padding-small));
    align-items: center;
    width: fit-content;
    backdrop-filter: blur(10px);
    padding-inline: var(--padding-small) 0;
    margin-block: var(--padding-small);
    border-radius: var(--corner-radius);
    overflow: clip;

    .names {
      display: flex;
      gap: var(--padding-small)
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      opacity: 0.5;
      background-color: var(--color-bg);
      z-index: -1;
    }
  }
}
</style>
