<script setup lang="ts">
import type { Featured, mastodon } from '#types'

const props = defineProps<{
  account: mastodon.v1.Account
}>()

const channel = computed(() => `timeline:featured:${toFullHandle(props.account.acct)}`)
const { stream } = useStreaming<{ type: string, item: Featured }>(channel)
const { items, status, error, loadNext } = await usePaginatorFetch<Featured>(
  '/api/timelines/featured',
  'id',
  computed(() => ({ type: stream.value?.type, payload: stream.value?.item })),
)
const endAnchor = useEndAnchor(loadNext)
</script>

<template>
  <ul class="account-reading-list">
    <CommonPaginator :items="items" v-bind="{ keyProp: 'id', pending: status === 'pending', error, loadNext }">
      <template #default="{ item }">
        <AccountReadingPersonCard v-if="item.account" :account="item.account" />
        <AccountReadingTagCard v-if="item.tag" :tag="item.tag" />
      </template>
      <template #done>
        <div ref="endAnchor" />
      </template>
    </CommonPaginator>
  </ul>
</template>

<style>
.account-reading-list {
  border-radius: var(--corner-radius);
  /* max-height: calc(100svh - var(--padding-base)* 2 - var(--base-size) - var(--padding-big)); */
}
</style>
