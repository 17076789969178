import { StorageSerializers } from '@vueuse/core'

const cache = useSessionStorage<Record<string, Record<string, unknown>>>('cache-accounts', {}, { serializer: StorageSerializers.object, initOnMounted: true, writeDefaults: false })

function setValue(acct: string, data?: Record<string, unknown>) {
  cache.value = {
    ...(cache.value ?? {}),
    [acct]: {
      ...(cache.value?.[acct] ?? {}),
      ...(data ?? {}),
    },
  }
}

export function useCache<T>(key: Ref<string>) {
  const { account } = useAuth()
  watch(account, (newAccount, oldAccount) => {
    if (oldAccount && oldAccount.acct !== newAccount?.acct) {
      delete cache.value?.[oldAccount.acct]
    }
    if (newAccount) {
      setValue(newAccount.acct)
    }
  }, { immediate: true })

  return computed<T | undefined>({
    get: () => {
      if (!account.value || !key.value) {
        return undefined
      }
      return cache.value?.[account.value.acct]?.[key.value] as T
    },
    set: (value: T | undefined) => {
      if (account.value && key.value) {
        if (cache.value?.[account.value.acct]?.[key.value] === undefined) {
          setValue(account.value.acct, { [key.value]: value })
        }
      }
    },
  })
}
