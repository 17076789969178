<script setup lang="ts">
import type { mastodon } from '#types'
import type { MenuItem } from '~/types'

const props = defineProps<{
  tag: mastodon.v1.Tag
}>()
const to = computed(() => ({ name: 'tags-id', params: { id: props.tag.id } }))
const actionsMenu:MenuItem[] = []
</script>

<template>
  <AccountReadingItem :to="to" :actions-menu="actionsMenu" class="reading-item-tag">
    <template #avatar>
      <span class="hash-avatar"><span class="icon-hash big" /></span>
    </template>
    <template #title>
      {{ tag.name }}
    </template>
    <template #stats>
      <div>
        {{ $t('account-posts_count', { count: +(tag.history?.[0].uses ?? '') }) }}
        {{ $t(`account-following_count`, { count: +(tag.history?.[0].accounts ?? '') }) }}
      </div>
    </template>
  </AccountReadingItem>
</template>

<style>
.reading-item-tag {
  --color-bg: var(--color-panel);
  .hash-avatar {
    width: var(--base-size);
    height: var(--base-size);
    display: grid;
    place-items: center;
  }
}
</style>
