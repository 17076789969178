<script setup lang="ts">
const emit = defineEmits(['toggle-followed'])
const { account } = useAuth()
const isSelf = computed(() => account.value?.id === useRoute().params?.account)
const prefOpen = ref(false)
</script>

<template>
  <footer class="footer-main">
    <NuxtLink v-if="account && !isSelf" :to="getAccountRoute(account)" class="avatar-box">
      <AccountAvatar :account="account" />
    </NuxtLink>
    <NuxtLink v-if="!account" to="/">
      <LogoIndi />
    </NuxtLink>
    <UiBar class="compact">
      <template v-if="account">
        <button class="compact" to="/" :title="$t('nav-explore')" @click="emit('toggle-followed')">
          <span class="icon-glasses" />
          <!-- <UiUnreadBadge /> -->
        </button>
        <NuxtLink class="button compact" to="/" :title="$t('nav-explore')">
          <span class="icon-explore" />
        </NuxtLink>
        <ConversationPopover />
        <SettingsPopupMenu @pref-open="prefOpen = $event" />
        <UiPopoverWidget
          v-if="prefOpen"
          :open="prefOpen"
          css-class="panel-minor"
          @request-close="prefOpen = false">
          <template #header-title>
            {{ $t('settings-profile-label') }}
          </template>
          <SettingsProfileEditor @request-close="prefOpen = false" />
        </UiPopoverWidget>
      </template>
      <template v-else>
        <NuxtLink class="button compact" to="/" :title="$t('nav-explore')">
          <span class="icon-explore" />
        </NuxtLink>
        <AuthSignUpWidget />
        <SettingsLanguage />
      </template>
    </UiBar>
  </footer>
</template>

<style lang='scss'>
footer.footer-main {
  --color-text: var(--color-panel-text);
  --spinner-size: var(--padding-base);

  position: sticky;
  bottom: var(--padding-big);
  display: flex;
  align-items: center;
  padding: 0 var(--padding-base);
  width: fit-content;

  .avatar-box {
    --s: calc(var(--badge-size) + var(--padding-base));
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--s);
    height: var(--s);
    border-radius: calc(var(--s) * var(--border-radius-fact));
    overflow: clip;
  }

  .tools {
    position: relative;
    border-radius: var(--border-radius-base);
    overflow: clip;
    display: flex;
    gap: var(--padding-mini);
  }

  .avatar-box,
  .tools {
    backdrop-filter: blur(10px);

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      opacity: 0.5;
      z-index: -1;
      background-color: var(--color-bg);
    }
  }

  .spinner-outline {
    --spinner-outline-size: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--spinner-outline-size);
    height: var(--spinner-outline-size);
    background-color: var(--color-bg);
    border-radius: 50%;
    overflow: hidden;
    transition: width 0.5s, height 0.5s;

    &.active {
      --spinner-outline-size: calc(var(--spinner-size) + var(--padding-base));
    }
  }

  .logo {
    width: calc(var(--base-size) * 2.5);
  }
}
.popover-widget-wrap.panel-minor {
  --pop-bg: var(--color-panel-minor);
}
</style>
