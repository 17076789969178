import { Link } from '@tiptap/extension-link'
import { Mention } from '@tiptap/extension-mention'
import PlaceholderPlugin from '@tiptap/extension-placeholder'
import { StarterKit } from '@tiptap/starter-kit'
import type { Editor } from '@tiptap/vue-3'
import { useEditor } from '@tiptap/vue-3'
import Embed from './tiptap/embed'
import {
  TiptapHashtagSuggestion,
  TiptapMentionSuggestion,
} from './tiptap/suggestion'
import { AudioVisualizer } from './tiptap/audio-visualizer'
import { TiptapPluginMediaViewer } from './tiptap/media-viewer'
import { htmlToText } from '#backend/utils/content-parse'

interface Options {
  content?: Ref<string>
  editable?: boolean
  autofocus?: boolean
}

export function useConversationEditor({
  content = ref(''),
  editable = true,
  autofocus = true,
}: Options = {}) {
  if (import.meta.server) {
    return {
      editor: ref<Editor | undefined>(),
      content,
      isEmpty: ref(true),
      setContent: () => {},
    }
  }

  const editor = useEditor({
    content: content.value,
    editable,
    extensions: [
      StarterKit.configure({
        history: {
          depth: 10,
        },
      }),
      Mention.configure({
        suggestion: TiptapMentionSuggestion,
      }),
      Mention.extend({ name: 'hashtag' }).configure({
        suggestion: TiptapHashtagSuggestion,
      }),
      PlaceholderPlugin.configure({
        placeholder: ({ node }) => {
          if (node.type.name === 'heading') {
            return `Heading ${node.attrs.level}`
          }
          return 'Write your message ...'
        },
        includeChildren: true,
      }),
      Link.configure({
        protocols: ['mailto'],
        autolink: false,
        openOnClick: false,
        linkOnPaste: false,
      }),
      Embed,
      AudioVisualizer,
      TiptapPluginMediaViewer.configure({ mini: true }),
    ],
    onUpdate({ editor }) {
      content.value = editor.getHTML()
    },
    editorProps: {
      attributes: {
        class: 'message-rich',
      },
    },
    autofocus,
  })

  return {
    editor,
    content,
    isEmpty: computed(() => {
      const draft = content.value.trim()
      if (!draft) {
        return true
      }
      const text = htmlToText(draft)
        .trim()
        .replace(/^(@\S+\s?)+/, '')
        .replaceAll(/```/g, '')
        .trim()
      return text.length === 0
    }),
    setContent: (value: string) => {
      editor.value?.commands.setContent(value)
      content.value = value
    },
  }
}
