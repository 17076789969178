<script setup lang="ts">
import { useFluent } from 'fluent-vue'
import type { MenuItem } from '~/types'

const emit = defineEmits<{ (evt: 'pref-open', item: boolean): void }>()

const { $t } = useFluent()
const { locales, locale } = useLocale()
const { logout } = useAuth()

const items = computed<MenuItem[]>(() => [
  {
    icon: 'language',
    label: $t('tooltip-change_language'),
    items: locales.map<MenuItem>(({ name, code }) => ({
      label: name,
      checked: locale.value === code,
      command: () => {
        locale.value = code
      },
    })),
  },
  {
    icon: 'user',
    label: $t('nav-profile'),
    command: () => {
      emit('pref-open', true)
    },
  },
  {
    separator: true,
    icon: '',
    label: $t('action-sign_out'),
    command: logout,
  },
])
</script>

<template>
  <UiPopupMenu :items="items">
    <template #trigger-title>
      <span class="icon-cog" />
    </template>
  </UiPopupMenu>
</template>

<style lang='scss'>
.settings-popover {
  position: relative;
}
</style>
