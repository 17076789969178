<script setup lang="ts">
import { EditorContent } from '@tiptap/vue-3'
import type { mastodon } from '#types'

const emit = defineEmits(['request-close'])
const { account } = useAuth()

const processing = ref(false)
const editedName = ref('')
const displayName = computed({
  get: () => (editedName.value || account.value?.displayName) ?? '',
  set: (name: string) => {
    editedName.value = name
  },
})
const content = ref(account.value?.note ?? '')
const { editor } = useConversationEditor({ content })

async function saveProfile() {
  const note = editor.value?.getHTML() ?? ''
  const formData = new FormData()
  formData.append('display_name', displayName.value)
  formData.append('note', note)
  processing.value = true
  await $fetch<mastodon.v1.Account>('/api/accounts/update_credentials', {
    headers: useRequestHeaders(['cookie']),
    method: 'PATCH',
    body: formData,
  })
  const profile = account.value
  if (profile) {
    account.value = {
      ...toRaw(profile),
      displayName: displayName.value,
      note,
    }
  }
  processing.value = false
  emit('request-close')
}
</script>

<template>
  <div v-if="account" class="settings-profile-editor">
    <div>
      <InputBox v-model="displayName" :label="$t('settings-profile-appearance-display_name')" />
    </div>
    <div>
      <label class="label">
        {{ $t('settings-profile-appearance-bio') }}
      </label>
      <EditorContent :editor="editor" class="message-input theme" />
    </div>
    <button :class="{ processing }" @click="saveProfile">
      <span>{{ $t('action-save') }}</span>
      <SpinnerIcon v-if="processing" />
    </button>
  </div>
</template>

<style lang='scss'>
.settings-profile-editor {
  position: relative;
  padding: var(--padding-base);
  display: grid;
  gap: var(--padding-base);

  // background-color: var(--color-panel-second);
  .ProseMirror {
    padding: var(--padding-small) var(--padding-base);
    background: var(--color-panel);
    border-radius: var(--corner-radius);
    margin-top: var(--padding-mini);

    &.ProseMirror-focused {
      outline: none;
      box-shadow: 0 0 0 3px var(--color-bg);
    }
  }

  button {
    position: relative;
    margin-left: auto;

    &.processing {
      span {
        opacity: 0.2;
      }
    }
  }
}
</style>
