<script setup lang="ts">
import { EditorContent } from '@tiptap/vue-3'
import type { mastodon, ConversationMessage } from '#types'
import type { UploadContentMedia } from '~/types'

const props = defineProps<{
  conversation: mastodon.v1.Conversation
  message?: ConversationMessage
}>()
const emit = defineEmits<{
  (evt: 'send', payload: { publishId: string, html: string }): void
}>()

const { $mediaUpload, $audioRecorder } = useNuxtApp()
const { editor, isEmpty, setContent } = useConversationEditor()

const isRecording = computed(() => $audioRecorder?.isRecording.value)

watch(() => props.message, (message) => {
  setContent(message?.content ?? '')
})

function start() {
  editor.value?.setEditable(false)
  editor.value?.chain().focus().startVisualizer().run()
  $audioRecorder?.startRecording()
}

function stop() {
  watchOnce($audioRecorder?.recordingBlob, (blob) => {
    if (blob) {
      const contentMedia = asContentMedia(blob)
      if (contentMedia?.id) {
        const file = new File([blob], contentMedia.id, { lastModified: new Date().getTime(), type: blob.type })
        publish({ files: [{ ...contentMedia, file }] })
      }
    }
  })
  clear()
}

function clear() {
  editor.value?.setEditable(true)
  setContent('')
  $audioRecorder?.stopRecording()
}

function send() {
  const content = editor.value?.getHTML()
  if (content) {
    setContent('')
    publish({ content })
  }
}

async function pickMedia() {
  editor.value?.setEditable(true)
  setContent('')
  const files = await pickFiles()
  publish({ files })
}

function publish({ files, content }: { files?: UploadContentMedia[], content?: string }) {
  const conversationId = props.conversation.id || props.conversation.accounts.slice().shift()?.acct
  if (conversationId) {
    const publishId = crypto.randomUUID()
    let html = content ?? ''
    if (files?.length) {
      html = `${createHtml(files, publishId)}${html}`
    }
    $mediaUpload?.send({ conversationId, publishId, uploadMedia: files, html })
    emit('send', { publishId, html })
  }
}
</script>

<template>
  <div class="conversation-message-editor">
    <EditorContent :editor="editor" class="message-input" />
    <button v-if="!isEmpty" class="compact" @click="send">
      <span class="icon-send" />
    </button>
    <template v-else-if="isRecording">
      <button class="compact" @click="clear">
        <span class="icon-trash" />
      </button>
      <button class="compact" @click="stop">
        <span class="icon-send" />
      </button>
    </template>
    <template v-else>
      <button class="compact" @click="start">
        <span class="icon-microphone" />
      </button>
      <button class="compact" @click="pickMedia">
        <span class="icon-media" />
      </button>
    </template>
  </div>
</template>

<style lang='scss'>
.conversation-message-editor {
  display: flex;
  align-items: flex-end;
  padding: var(--padding-base) var(--padding-small);
  width: 100%;
  gap: var(--padding-small);
  // background-color: var(--color-panel);

  button {
    flex-shrink: 0;
  }

  .message-input {
    flex-grow: 1;

    .ProseMirror {
      padding: var(--padding-small) var(--padding-base);
      background: var(--color-panel);
      border-radius: var(--border-radius-base);
      margin-top: var(--padding-mini);

      &.ProseMirror-focused {
        outline: none;
        box-shadow: 0 0 0 3px var(--color-bg);
      }
    }

    .message-rich {
      padding: var(--padding-small);
      background: white;
      border-radius: var(--corner-radius);
    }
  }
}
</style>
