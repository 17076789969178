<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { Props } from 'tippy.js'

const props = withDefaults(defineProps<{
  arrow?: Props['arrow']
  theme?: Props['theme']
  delay?: number | [number, number]
  duration?: number
}>(),
{
  arrow: false,
  theme: 'indi',
  delay: () => [500, 0],
  duration: 1000
})

const emit = defineEmits(['state'])

const tippy = ref<typeof Tippy>()
const durationMs = ref(100)

function getBody () {
  return document?.body
}

const isOpen = ref(false)
const show = computed(() => () => {
  isOpen.value = true
  tippy.value?.show()
})
const close = computed(() => () => {
  isOpen.value = false
  tippy.value?.hide()
})

defineExpose({ show, close, isOpen })
</script>

<template>
  <Tippy
    ref="tippy"
    class="tippy-popup"
    interactive
    allow-html
    :theme="props.theme"
    :arrow="props.arrow"
    :delay="props.delay"
    :hide-on-click="true"
    :append-to="getBody"
    :offset="[0, 8]"
    :duration="durationMs"
    placement="top"
    @state="emit('state', $event)">
    <template #default="{ state }">
      <slot :state="state" />
    </template>
    <template #content="{ state, hide }">
      <UiCollapsibleBox
        v-if="state.isVisible"
        class="tooltip-content"
        :open="state.isVisible"
        :t="`${durationMs}ms`">
        <slot name="tooltip" :close="hide" />
      </UiCollapsibleBox>
    </template>
  </Tippy>
</template>
<style>
.tooltip-content {
  padding: var(--padding-mini) var(--padding-small);
}
</style>
