<script setup lang="ts">
import { useFluent } from 'fluent-vue'
import type { ConversationMessage } from '#types'
import type { MenuItem } from '~/types'

const props = defineProps<{
  message: ConversationMessage
  own: boolean
}>()
const emit = defineEmits<{ (evt: 'remove' | 'edit'): void }>()

const disabled = ref(false)

const { $t } = useFluent()
const { $mediaUpload } = useNuxtApp()

const uploading = computed(() => $mediaUpload?.isUploading(props.message.publishId).value)
const uploadProgress = computed<number>(() => (uploading.value && props.message.publishId) ? ($mediaUpload?.progress.get(props.message.publishId)?.percentage ?? 0) : 0)

const toolsMenu = computed<MenuItem[]>(() => [
  {
    label: $t('menu-edit'),
    command: () => emit('edit'),
  },
  {
    label: $t('menu-delete'),
    command: () => emit('remove'),
  },
])
</script>

<template>
  <div class="conversation-message">
    <div class="body" :class="{ disabled }">
      <ContentRich :content="message.content" />
      <div class="tools">
        <UiPopupMenu :items="toolsMenu">
          <template #trigger-title>
            <span v-if="uploading">{{ uploadProgress }}</span>
            <span v-else-if="message.state === 'failed'" class="icon-error" />
            <span v-else class="icon-more" />
          </template>
        </UiPopupMenu>
      </div>
    </div>
    <footer>
      <div class="time">
        {{ localizedTime(message.createdAt) }}
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
.conversation-message {
  position: relative;
  background-color: var(--message-bg);
  border-radius: var(--corner-radius) var(--corner-radius) var(--corner-radius) 0;
  width: fit-content;
  overflow: clip;

  &:not(:first-child) {
    border-start-start-radius: 0;
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  .body {
    position: relative;
    --content-padding: var(--padding-small);
    --content-template-columns: var(--padding-small) 1fr var(--button-height);
    --content-block-margin: var(--content-padding);
  }

  // .content {
  //   display: grid;
  //   gap: var(--padding-mini);
  //   grid-template-columns: var(--padding-base) 1fr var(--size-two-third);
  //   // padding: var(--padding-medium) 0 var(--padding-small) var(--padding-base);
  // }

  p {
    margin: 0;
  }

  .tools {
    position: absolute;
    right: 0;
    top: 0;
    display: grid;
    grid-auto-rows: var(--button-height) 1fr;
  }

  footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .time {
    font-size: var(--font-size-caption);
    text-align: center;
    padding: 0 var(--padding-mini) var(--padding-mini);
    opacity: 0.7;
  }
}
</style>
