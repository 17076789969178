<script setup lang="ts">
import { StorageSerializers, useStorage } from '@vueuse/core'
import type { mastodon, ConversationMessage } from '#types'

const popover = ref()

const { $mediaUpload } = useNuxtApp()
const { account } = useAuth()
const { stream } = useStreaming<{ type: string, conversationId: string, status: ConversationMessage }>(computed(() => {
  const accountId = account.value?.id
  return accountId ? `timeline:direct:${accountId}` : undefined
}))

const openConversation = useState<mastodon.v1.Account | null>('open-conversation')
const selectedConversation = useStorage<mastodon.v1.Conversation | undefined>('conversation', null, undefined, { serializer: StorageSerializers.object })

const { data: records, refresh } = await useFetch<mastodon.v1.Conversation[]>('/api/conversations', { default: () => [] })

const conversations = computed<mastodon.v1.Conversation[]>(() => [
  ...records.value,
  ...(selectedConversation.value?.id === '' ? [selectedConversation.value] : []),
])
const unread = computed<boolean>(() => records.value.some(({ unread }) => unread))

watch(records, (items) => {
  if (items.length) {
    if (selectedConversation.value?.id === '') {
      const account = selectedConversation.value.accounts.slice().shift()
      if (account) {
        const conversation = records.value.filter(({ accounts }) => accounts.length === 2).find(({ accounts }) => accounts.find(({ id }) => compareHandle(account.id, id)))
        if (conversation) {
          selectedConversation.value = conversation
        }
      }
    }
    if (!selectedConversation.value) {
      selectedConversation.value = records.value.slice().shift()
    }
  }
})

watch(openConversation, (account) => {
  if (account) {
    let conversation = records.value.filter(({ accounts }) => accounts.length === 2).find(({ accounts }) => accounts.find(({ id }) => compareHandle(account.id, id)))
    if (!conversation) {
      conversation = {
        id: '',
        accounts: [account],
        unread: false,
      }
    }
    selectedConversation.value = conversation
    popover.value?.show()
    openConversation.value = null
  }
})

if (import.meta.client) {
  watch(stream, (message) => {
    if (message) {
      const { type, conversationId } = message
      if (type === 'create' && selectedConversation.value?.id !== conversationId) {
        const conversation = records.value.find(({ id }) => id === conversationId)
        if (conversation) {
          conversation.unread = true
        }
        else {
          refresh()
        }
      }
    }
  })

  watch(stream, (message) => {
    if (message) {
      const { type, conversationId, status: record } = message
      const items = useCache<ConversationMessage[]>(computed(() => `conversation-${conversationId}`)).value
      if (items) {
        const index = items.findIndex(({ id, publishId }) => (id && id === record.id) || (publishId && publishId === record.publishId)) ?? -1
        if (index >= 0) {
          $mediaUpload?.clean(items[index]?.publishId)
        }
        if (type === 'create') {
          if (index >= 0) {
            items.splice(index, 1)
          }
          items.unshift(record)
        }
        if (type === 'update') {
          if (index >= 0) {
            items[index] = record
          }
        }
        if (type === 'delete') {
          if (index >= 0) {
            items.splice(index, 1)
          }
        }
      }
    }
  })

  watch($mediaUpload?.error, (value) => {
    if (value) {
      const { conversationId, publishId, error } = value
      console.error(error)
      const items = useCache<ConversationMessage[]>(computed(() => `conversation-${conversationId}`)).value
      if (items) {
        const index = items.findIndex(item => publishId === item.publishId)
        if (index >= 0) {
          items[index] = {
            ...items[index],
            state: 'failed',
          }
        }
      }
    }
  })
}

function selectConversation(conversation: mastodon.v1.Conversation) {
  selectedConversation.value = conversation
}

async function removeConversation() {
  const conversationId = selectedConversation.value?.id
  if (conversationId) {
    useCache<ConversationMessage[]>(ref(`conversation-${conversationId}`)).value = []
    records.value = records.value.filter(({ id }) => conversationId !== id)
    selectedConversation.value = records.value.slice()?.shift()
    const headers = useRequestHeaders(['cookie'])
    await $fetch(`/api/conversations/${conversationId}`, { method: 'DELETE', headers })
  }
}
</script>

<template>
  <UiPopoverFloat ref="popover" placement="bottom-left" css-class="chat-popover">
    <template #trigger-title>
      <span class="icon-chat" />
      <UiUnreadBadge :count="unread" />
    </template>
    <div class="chat-panel">
      <ConversationList
        :conversations="conversations"
        :conversation="selectedConversation"
        @select="selectConversation"
      />
      <ConversationPanel
        v-if="selectedConversation && account"
        :account="account"
        :conversation="selectedConversation"
        @remove="removeConversation"
      />
    </div>
  </UiPopoverFloat>
</template>

<style lang='scss'>
.chat-popover {
  --pop-float-bottom: calc(var(--button-height) + var(--padding-big) + var(--padding-base));
  height: calc(var(--base-size) * 12);
}

.chat-panel {
  container-type: inline-size;
  display: grid;
  grid-template-columns: calc(var(--base-size) + var(--padding-base) * 2) 1fr;
  gap: var(--size-two-third);
  background-color: var(--color-panel-minor);
  height: 100%;

  .chat-list {
    --scrollbar-w: 1px;
    width: calc(100% + var(--scrollbar-w));
    padding-inline: var(--padding-base);
    overflow: auto;
    overscroll-behavior: contain;
    scrollbar-width: thin;
    display: grid;
    gap: var(--padding-medium);
    padding-block: var(--padding-base);
    align-items: center;
    justify-content: center;
    height: fit-content;
    max-height: 100%;

    &::-webkit-scrollbar {
      width: var(--scrollbar-w);
      height: var(--scrollbar-w);
      background: white; // var(--color-line);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-line-dark, #000);
    }
  }

  .chat-room {
    overflow: auto;
    overscroll-behavior: contain;
    padding: var(--padding-base);
    padding-inline-start: 0;
  }
}
</style>
