<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{ account: mastodon.v1.Account }>()
const theme = useTheme(computed(() => props.account.theme))
</script>

<template>
  <AccountReadingItem :to="getAccountRoute(account)" class="theme" :style="theme">
    <template #avatar>
      <AccountAvatar :account="account" sizes="96px" />
    </template>
    <template #title>
      {{ getDisplayName(account, { rich: true }) }}
    </template>
    <template #stats>
      <AccountStats :account="account" short />
    </template>
  </AccountReadingItem>
</template>
