<script setup lang="ts">
import type { mastodon } from '#types'

const props = withDefaults(defineProps<{
  conversation: mastodon.v1.Conversation
  tag?: string
}>(), {
  tag: 'div',
})

const adjacentAvatarOffset = 10 // %

const accounts = computed(() => props.conversation.accounts?.filter(account => !isSelfAccount(account)) ?? [])

const avatarsStyle = computed(() => {
  if (!accounts.value?.length) {
    return
  }
  return {
    '--w': `${100 - (accounts.value.length - 1) * adjacentAvatarOffset}%`,
  }
})

const name = computed(() => accounts.value?.map(account => getDisplayName(account)).join(', '))
</script>

<template>
  <Component :is="props.tag" class="conversation-card">
    <UiTooltip>
      <div class="conversation-avatars" :style="avatarsStyle">
        <AccountAvatar
          v-for="(acc, i) in accounts"
          :key="acc.id"
          :account="acc"
          :style="{ '--shift': adjacentAvatarOffset * i + '%' }"
        />
        <UiUnreadBadge :count="conversation.unread" />
      </div>
      <template #tooltip>
        {{ name }}
      </template>
    </UiTooltip>
  </Component>
</template>

<style lang='scss'>
.conversation-card {
  position: relative;

  &.active {
    z-index: 1;
    background: white;
    border-radius: var(--border-radius-base);
    box-shadow: 0 0 0 5px white;
  }

  .conversation-avatars {
    --w: 100%;
    position: relative;
    display: block;
    width: var(--base-size);
    height: var(--base-size);

    .account-avatar {
      --shift: 0;
      position: absolute;
      left: var(--shift);
      top: var(--shift);
      width: var(--w);
      height: var(--w);
    }
  }
}
</style>
