<script setup lang="ts">
import type { mastodon } from '#types'

defineProps<{
  conversations: mastodon.v1.Conversation[]
  conversation?: mastodon.v1.Conversation
}>()

const emit = defineEmits<{ (evt: 'select', item: mastodon.v1.Conversation): void }>()
</script>

<template>
  <ul class="chat-list">
    <ConversationCard
      v-for="item in conversations"
      :key="item.id"
      tag="li"
      :class="{ active: item.id === conversation?.id }"
      :conversation="item"
      @click="emit('select', item)"
    />
  </ul>
</template>
