<script setup lang="ts">
import type { mastodon, ConversationMessage } from '#types'
import type { ConversationMessageGroup } from '~/types'

const props = defineProps<{
  group: ConversationMessageGroup
  conversation: mastodon.v1.Conversation
}>()
const emit = defineEmits<{
  (evt: 'remove' | 'edit', item: ConversationMessage): void
}>()

const messages = computed(() => props.group.messages)
const acc = computed(() => props.group.account)
const own = computed(() => props.group.own)
</script>

<template>
  <li class="conversation-message-group" :class="{ own }">
    <span class="avatar">
      <UiTooltip v-if="!own">
        <AccountAvatar :account="acc" />
        <template #tooltip>
          <span>{{ getDisplayName(acc) }}</span>
        </template>
      </UiTooltip>
    </span>
    <div class="messages">
      <ConversationMessage
        v-for="message in messages"
        :key="message.createdAt"
        :message="message"
        :own="own"
        @remove="emit('remove', message)"
        @edit="emit('edit', message)"
      />
    </div>
  </li>
</template>

<style lang='scss'>
.conversation-message-group {
  --badge-size: var(--size-two-third);
  --message-bg: var(--color-panel);
  display: grid;
  grid-template-columns: var(--badge-size) 1fr;
  gap: var(--padding-small);
  align-items: end;
  margin-bottom: var(--padding-base);

  &:not(.own) .avatar,
  &.own .messages {
    &::after {
      --tail-size: var(--padding-small);
      content: '';
      position: absolute;
      bottom: 0;
      left: 100%;
      height: var(--tail-size);
      width: var(--tail-size);
      border-style: solid;
      border-width: calc(var(--tail-size) / 2) calc(var(--tail-size) / 2);
      border-color: transparent var(--message-bg) var(--message-bg) transparent;
    }
  }

  .avatar {
    position: sticky;
    bottom: 0;
  }

  .messages {
    container-type: inline-size;
    width: 100%;

    .media-viewer {
      container-type: normal;
    }

    img,
    video {
      width: 100cqi;
      max-width: 100%;
    }
  }

  &.own {
    --message-bg: var(--color-panel);
    justify-items: end;

    .messages {
      &:last-child::after {
        border-color: transparent transparent var(--message-bg) var(--message-bg);
      }

    }

    .conversation-message {
      border-radius: var(--corner-radius) var(--corner-radius) 0 var(--corner-radius);
      margin-inline-start: auto;

      &:not(:first-child) {
        border-start-end-radius: 0;
      }
    }
  }
}
</style>
