<script setup lang="ts">
const { account } = useAuth()
const route = useRoute()
const lastAsideOpen = useCookie<boolean>('asideOpen', {
  sameSite: 'lax',
})
const aside = ref<HTMLElement>()
const statusId = ref<string | undefined>()
const asideOpen = computed(() => account.value && lastAsideOpen.value)

const theme = useTheme(computed(() => account.value?.theme))

useHead({
  htmlAttrs: { style: Object.entries(theme.value).reduce<string>((str, [key, val]) => `${str ? str + ' ' : str}${key}: ${val};`, '') }
})

watch(theme, (theme) => {
  const style = document.documentElement.style
  Object.entries(theme).forEach(([prop, value]) => {
    style.setProperty(prop, value)
  })
})

watch(() => route.fullPath, () => {
  statusId.value = typeof route.query.s === 'string' ? route.query.s : undefined
}, { immediate: true })

function toggleAside() {
  lastAsideOpen.value = !lastAsideOpen.value
}

onMounted(() => {
  aside.value?.addEventListener('click', () => {
    if (window.innerWidth < 700) {
      lastAsideOpen.value = false
    }
  })
})
</script>

<template>
  <div class="main-wrap" :class="{ 'aside-open': asideOpen }">
    <aside ref="aside" :style="theme" class="theme">
      <header>
        <button class="compact" @click="lastAsideOpen = false">
          <span class="icon-chevron-left" />
        </button>
      </header>
      <AccountReadingList v-if="account" :account="account" />
    </aside>
    <main>
      <slot />
    </main>
    <StatusLoader v-if="statusId" :id="statusId" />
    <FooterMain :style="theme" class="theme" @toggle-followed="toggleAside" />
    <PortalTarget name="modal" />
    <PortalTarget name="overlays" />
  </div>
</template>

<style lang="scss">
:root {
  --badge-size: var(--size-two-third);
}

// .spinner-wrap {
//   position: fixed;
//   top: 100px;
//   left: 100px;
//   z-index: 2000000;
// }

.main-wrap {
  display: grid;
  grid-template-columns: 0 1fr;
  transition: grid-template-columns 0.2s;

  &.aside-open {
    grid-template-columns: min(320px, 100vw) 1fr;
  }

  &>aside {
    overflow: hidden;
    background-color: var(--color-panel-second);
    position: sticky;
    top: 0;
    height: 100svh;
    overflow: auto;
    overscroll-behavior: contain;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: calc(var(--padding-base) * 2 + var(--base-size) + var(--padding-big));

    >* {
      width: min(320px, 100vw);
      padding-inline: var(--padding-mini);
    }

    header {
      display: flex;
      justify-content: flex-end;
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  &>footer {
    z-index: 10;
  }

  @media (width < 700px) {
    &>aside {
      grid-column: 1;
      grid-row: 1;
      z-index: 1;
      background-color: color-mix(in lab, var(--color-panel-second) 70%, transparent);
      backdrop-filter: blur(15px);
    }

    &>main {
      grid-column: 1 / 3;
      grid-row: 1;
      z-index: 0;
    }
  }
}

main {
  container-type: inline-size;
}

.grid-container {
  --max-width: 75rem;
  container-type: inline-size;
  margin-inline: auto;
  width: min(var(--max-width), 100cqi);
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-rows: calc(240px + var(--button-height));
  grid-auto-flow: dense;
  gap: var(--grid-gap);
  transition: gap 0.2s;
  width: 100%;
  padding-inline: var(--padding-small);

  &:not(.uniform) > * {
    &.horizontal {
      @media (width > calc(480px + 3rem)) {
        grid-column: span 2;
      }
    }

    &.vertical {
      @media (height > calc(480px + 3rem)) {
        grid-row: span 2;
      }
    }

    &.large,
    &.big {
      @media (width > calc(480px + 3rem)) {
        grid-column: span 2;
      }

      @media (height > calc(480px + 3rem)) {
        grid-row: span 2;
      }
    }
  }
}
</style>
