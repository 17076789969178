import { Node, mergeAttributes, VueNodeViewRenderer } from '@tiptap/vue-3'
import AudioVisualizerView from '~/components/tiptap/views/AudioVisualizerView.vue'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    'audio-visualizer': {
      startVisualizer: () => ReturnType
    }
  }
}

export const AudioVisualizer = Node.create<{ HTMLAttributes: Record<string, any> }>({
  name: 'audio-visualizer',
  group: 'block',
  draggable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {}
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-${this.name}]`
      }
    ]
  },

  addAttributes() {
    return {
      [this.name]: {
        default: null
      }
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(AudioVisualizerView)
  },

  addCommands() {
    return {
      startVisualizer: () => ({ editor, commands: { insertContent, updateAttributes } }) => {
        if (editor.isActive(this.name)) {
          return updateAttributes(this.name, { [`data-${this.name}`]: true })
        } else {
          editor.setEditable(false)
          return insertContent({
            type: this.name,
            attrs: { [`data-${this.name}`]: true }
          })
        }
      }
    }
  }
})
